/* NavBar.css */
/* NavBar.css */

.navbar {
  position: fixed !important;
  padding-left: 10px !important;
  justify-content: center;
  width: calc(100% - 15px); /* Adjust width to account for scrollbar */
  top: 0;
  left: 0;
  z-index: 10; /* Ensure it's above other content but not too high */
  background-color: black;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  overflow-x: hidden; /* Ensure no horizontal overflow */
}

.navbar a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
  position: relative; /* Needed for the underline effect */
  display: inline-block; /* Ensure pseudo-element aligns correctly */
}

.navbar a::after {
  content: '';
  position: absolute;
  bottom: 10%;
  left: 50%; /* Center the underline horizontally */
  width: 0%; /* Initial width of the underline (hidden) */
  height: 2px;
  background-color: #9acafc;
  transform: translateX(-50%); /* Center align the underline */
  transition: width 0.3s ease; /* Smooth transition for width */
}

.navbar a:hover::after {
  width: 80%; /* Adjust this value for the desired underline length */
}

.navbar a:hover {
  color: #9acafc;
}

.nav-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
